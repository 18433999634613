import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as RankStampAPI from "@/api/analysis/rank-stamp";
import { isSuccess } from "@/api/response";
import { RankStampGetRequest } from "@/api/analysis/rank-stamp/request";
import {
  RankStampGetResponse,
  RankStampGraphData
} from "@/api/analysis/rank-stamp/response";

const MODULE_NAME = "analysis/rank-stamp/get";

/**
 *  ランク(スタンプ)API（/analysis-rank-stamp）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: RankStampGetRequest = {} as RankStampGetRequest;
  getResponse: RankStampGetResponse = {} as RankStampGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getGraphData() {
    if (this.getResponse.results) {
      return this.getResponse.results.graphData;
    } else {
      return [] as RankStampGraphData[];
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: RankStampGetRequest) {
    const getResponse = await RankStampAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as RankStampGetResponse
    };
  }
}

export default getModule(Get);
