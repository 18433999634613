import service from "@/api/service";
import { RankStampGetRequest } from "@/api/analysis/rank-stamp/request";
import { RankStampGetResponse } from "@/api/analysis/rank-stamp/response";

/**
 * ランク(スタンプ)APIをコールします。
 *
 * @param getRequest ランク(スタンプ)APIのリクエストボディ
 * @return RankStampGetResponse
 */
export async function get(getRequest: RankStampGetRequest) {
  const response = await service.post("/analysis-rank-stamp", getRequest);
  return response.data as RankStampGetResponse;
}
