import { Component, Mixins } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import RankStampChart from "@/views/analysis/RankStampChart.vue";
import RankStampGet from "@/store/analysis/rank-stamp/get";
import { RankStampGetRequest } from "@/api/analysis/rank-stamp/request";
import Admin from "@/store/admin/admin";

export interface RankStampInputOptions {
  date: string;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    RankStampChart
  }
})
export default class AnalysisRankStamp extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ランク (スタンプ)";
  headingSub = "Rank (Stamp)";
  breadCrumbs = [{ text: "ランク (スタンプ)", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedRankStampData = false;

  // DatePickerの表示フラグ
  dateModal = null;

  // 検索入力オプション
  inputOptions: RankStampInputOptions = {
    date: this.initDate,
    selectShop: null
  };

  get rankStampCharts() {
    return RankStampGet.getGraphData;
  }

  get dateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.date);
  }
  set dateForView(date) {
    this.inputOptions.date = this.replaceSlashToHyphen(date);
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDate() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      d = date.getDate();
    const firstDate = new Date(y, m, d);
    return this.stringFromDate(firstDate);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.inputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await RankStampGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedRankStampData = false;
    await this.fetchRankStamp();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchRankStamp(): Promise<boolean> {
    this.isLoadedRankStampData = false;
    await Flash.clear();
    const rankStampGetRequest: RankStampGetRequest = {
      date: this.dateForView,
      searchShopId: this.inputOptions.selectShop
        ? Number(this.inputOptions.selectShop.id)
        : null
    };
    await RankStampGet.get(rankStampGetRequest);
    if (!RankStampGet.isSuccess) {
      await Flash.setErrorNow({
        message: RankStampGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedRankStampData = true;
    return RankStampGet.isSuccess;
  }
}
